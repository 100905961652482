import { gql } from "@apollo/client"

export const CREATE_CLUB = gql`
    mutation createClub(
        $clubId: String
        $name: String
        $street: String
        $hausNr: String
        $plz: String
        $city: String
        $email: String
        $password: String
    ) {
        createClub(
            clubId: $clubId
            name: $name
            street: $street
            hausNr: $hausNr
            plz: $plz
            city: $city
            email: $email
            password: $password
        ) {
            id
        }
    }
`
