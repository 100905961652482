import React, { Component } from "react"
import { User } from "../types"
import { noopUser } from "../utils/noop"
const { Provider, Consumer } = React.createContext({
    user: { id: "" },
    updateUser: noopUser,
})

class UserContextProvider extends Component {
    state = {
        user: {
            id: "userId",
        },
    }

    updateUser = (user: User) => {
        this.setState({ user })
    }

    render() {
        return (
            <Provider value={{ user: this.state.user, updateUser: this.updateUser }}>
                {this.props.children}
            </Provider>
        )
    }
}

export { UserContextProvider, Consumer as UserContextConsumer }
