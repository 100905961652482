import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
import InputIcon from "@material-ui/icons/Input"

const useStyles = makeStyles(
    (theme) => ({
        root: {
            textAlign: "right",
            marginBottom: theme.spacing(2),
        },
        toolbar: theme.mixins.toolbar,
        button: {
            color: theme.palette.primary.light,
        },
    }),
    { name: "ActionBar" }
)

export default function ActionBar() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.toolbar} />
            <Button
                className={classes.button}
                endIcon={<InputIcon />}
                component="a"
                href="https://app.panobreeder.de"
                target="_blank"
            >
                Zur Züchter App
            </Button>
        </div>
    )
}
