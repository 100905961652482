import {
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import moment from "moment"

const useStyles = makeStyles(
    (theme) => ({
        root: {
            height: "100%",
        },
    }),
    { name: "NotificationList" }
)

const notifications = [
    {
        id: "1",
        title: "Neue Zuchtmeldung",
        date: new Date(),
    },
]

export default function NotificationList() {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Neuigkeiten"
                subheader="Zuchtmeldungen und weitere Benachrichtigungen"
            />
            <List>
                {notifications.map((notification) => (
                    <ListItem button>
                        <ListItemAvatar>
                            <InfoIcon />
                        </ListItemAvatar>
                        <ListItemText
                            primary={notification.title}
                            secondary={moment(notification.date).format(
                                "ddd, DD.MM.YYYY HH:mm"
                            )}
                        />
                        <ListItemSecondaryAction />
                    </ListItem>
                ))}
            </List>
        </Card>
    )
}
