import {
    Button,
    Card,
    CardActions,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
} from "@material-ui/core"
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot"
import DescriptionIcon from "@material-ui/icons/Description"
import { Add } from "@material-ui/icons"

const litterList = [
    {
        id: "1",
        name: "Englischer Widder",
        amount: 17,
        breeder: 3,
    },
    {
        id: "2",
        name: "Deutscher Riese",
        amount: 5,
        breeder: 1,
    },
    {
        id: "3",
        name: "Zwergwidder",
        amount: 27,
        breeder: 3,
    },
]

export default function LitterList() {
    return (
        <Card>
            <CardHeader
                title="Rassen"
                subheader="Alle Rassen, die im Verein gezüchtet werden"
            />
            <List>
                {litterList.map((breed) => (
                    <ListItem button>
                        <ListItemAvatar>
                            <ScatterPlotIcon />
                        </ListItemAvatar>
                        <ListItemText
                            primary={breed.name}
                            secondary={`${breed.amount} Tiere / ${breed.breeder} Züchter`}
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title="Dokumente erstellen">
                                <IconButton edge="end">
                                    <DescriptionIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <CardActions>
                <Button variant="contained" color="primary" startIcon={<Add />}>
                    Neue Rasse
                </Button>
            </CardActions>
        </Card>
    )
}
