import {
    Card,
    CardContent,
    CardHeader,
    makeStyles,
    Typography,
    Grid,
} from "@material-ui/core"

const useStyles = makeStyles(
    (theme) => ({
        root: {
            height: "100%",
        },
        content: {
            textAlign: "center",
        },
    }),
    { name: "Statistic" }
)

export default function Statistic() {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Statistik"
                subheader={`Für das Zuchtjahr ${
                    new Date().getFullYear() - 1
                } / ${new Date().getFullYear()}`}
            />
            <CardContent className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">17</Typography>
                        <Typography variant="body1">Würfe</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">86</Typography>
                        <Typography variant="body1">Tiere</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">2</Typography>
                        <Typography variant="body1">Rassen</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">6</Typography>
                        <Typography variant="body1">Aktive Züchter</Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">2</Typography>
                        <Typography variant="body1">
                            Veranstaltete Schauen
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography variant="h2">7</Typography>
                        <Typography variant="body1">Auszeichnungen</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
