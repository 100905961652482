import {
    CardActions,
    CardContent,
    CardHeader,
    TextField,
    Grid,
    Snackbar,
} from "@material-ui/core"
import { useState } from "react"
import { ClubRegistrationData } from "../../../../types"
import LoadingButton from "../../../shared/button/LoadingButton"
import Alert from "@material-ui/lab/Alert"
import { createClub } from "../../../../config/graphql.service"
import { AlertTitle } from "@material-ui/lab"
import crypto from "crypto"
import { makeStyles } from "@material-ui/core/styles"

const defaultRegistrationData: ClubRegistrationData = {
    city: "",
    clubId: "",
    email: "",
    hausNr: "",
    name: "",
    password: "",
    plz: "",
    street: "",
}

const useStyles = makeStyles(() => ({
    snackbar: {
        maxWidth: 500,
    },
}))

export default function Registration() {
    const classes = useStyles()
    const [club, setClub] = useState<ClubRegistrationData>(defaultRegistrationData)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault()
        setLoading(true)

        const tempClub: ClubRegistrationData = defaultRegistrationData
        let error: boolean = false

        // trim values and check if field is still valid
        Object.keys(club).map((key) => (tempClub[key] = club[key].trim()))
        Object.keys(tempClub).map((key) => {
            if (!tempClub[key]) {
                error = true
            }

            return true
        })

        if (error) {
            setError("Bitte fülle alle Felder aus!")
            setClub(tempClub)
            setLoading(false)
            return
        } else {
            setError("")
        }

        const hash = crypto.createHash("sha1")
        hash.update(tempClub.password)
        tempClub.password = hash.digest("hex")

        const res = await createClub(tempClub)

        if (res) {
            setError("")
            setClub({
                city: "",
                clubId: "",
                email: "",
                hausNr: "",
                name: "",
                password: "",
                plz: "",
                street: "",
            })
            setSuccessMessage(
                `Eine E-Mail wurde an ${tempClub.email} gesendet. Bitte bestätige diese.`
            )
        } else {
            setSuccessMessage("")
            setError(
                "Vereinskonto konnte nicht erstellt werden! Bitte melde dich unten rechts im Chat."
            )
        }

        setLoading(false)
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const name: string = e.target.name
        const value: string = e.target.value

        setClub({ ...club, [name]: value })
    }

    function handleErrorClose() {
        setError("")
    }

    return (
        <form onSubmit={handleSubmit}>
            <CardHeader
                title="Registrierung"
                subheader="Lege ein neues Konto für deinen Verein an"
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Vereinskennung"
                            name="clubId"
                            value={club.clubId}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="Vereinsname"
                            name="name"
                            value={club.name}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            label="Straße"
                            name="street"
                            value={club.street}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            label="Hausnr."
                            name="hausNr"
                            value={club.hausNr}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            label="PLZ"
                            name="plz"
                            value={club.plz}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            fullWidth
                            label="Ort"
                            name="city"
                            value={club.city}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            type="email"
                            label="E-Mail Adresse"
                            name="email"
                            value={club.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            type="password"
                            label="Kennwort"
                            name="password"
                            value={club.password}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <LoadingButton
                    color="primary"
                    type="submit"
                    variant="contained"
                    loading={loading}
                >
                    Vereinskonto erstellen
                </LoadingButton>
            </CardActions>
            <Snackbar
                className={classes.snackbar}
                open={!!error}
                autoHideDuration={10000}
                onClose={handleErrorClose}
            >
                <Alert severity="error">
                    <AlertTitle>Fehler bei der Registrierung</AlertTitle>
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                className={classes.snackbar}
                open={!!successMessage}
                autoHideDuration={10000}
                onClose={handleErrorClose}
            >
                <Alert severity="success">
                    <AlertTitle>Vereinskonto erstellt</AlertTitle>
                    {successMessage}
                </Alert>
            </Snackbar>
        </form>
    )
}
