import { Router, Route, Switch } from "react-router-dom"
import history from "./utils/history"
import LoginContainer from "./components/scenes/login/LoginContainer"
import DashboardContainer from "./components/scenes/dashboard/DashboardContainer"
import Header from "./components/shared/header/Header"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100% - 32px)",
        padding: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: 240,
        },
    },
}))

export default function AppRouter() {
    const classes = useStyles()

    return (
        <Router history={history}>
            <div id="router-root">
                <Header />
                <div className={classes.root}>
                    <Switch>
                        <Route path="/" exact component={DashboardContainer} />
                        <Route path="/login" component={LoginContainer} />
                    </Switch>
                </div>
            </div>
        </Router>
    )
}
