// @ts-nocheck
import * as React from "react"
import { UserContextConsumer } from "./userContext"
import { UserContext } from "../types"

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function withUserContext<
    P extends { context?: UserContext },
    R = Omit<P, "context">
>(Component: React.FunctionComponent<P>): React.FC<R> {
    return function BoundComponent(props: R) {
        return (
            <UserContextConsumer>
                {(context) => <Component {...props} context={context} />}
            </UserContextConsumer>
        )
    }
}
