import { Button, ButtonProps, CircularProgress, makeStyles } from "@material-ui/core"
import { InferProps } from "prop-types"

const useStyles = makeStyles(
    () => ({
        root: {
            position: "relative",
        },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
    }),
    { name: "LoadingButton" }
)

interface Props {
    loading: string
}

export default function LoadingButton({
    children,
    loading,
    color,
    type,
    variant,
}: InferProps<ButtonProps & Props>) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Button color={color} type={type} variant={variant} disabled={loading}>
                {children}
            </Button>
            {loading && (
                <CircularProgress size={24} className={classes.buttonProgress} />
            )}
        </div>
    )
}
