import {
    Avatar,
    Card,
    List,
    ListItem,
    makeStyles,
    ListItemSecondaryAction,
    ListItemAvatar,
    ListItemText,
    Tooltip,
    IconButton,
    CardHeader,
    CardActions,
    Button,
} from "@material-ui/core"
import EmailIcon from "@material-ui/icons/Email"
import PersonAddIcon from "@material-ui/icons/PersonAdd"

const useStyles = makeStyles(
    (theme) => ({
        root: { height: "100%" },
        content: {
            overflow: "scroll",
        },
    }),
    { name: "BreederList" }
)

const mockUser = [
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
    {
        firstName: "Peter",
        lastName: "Müller",
        email: "p.ben@gmx.net",
    },
]

export default function BreederList() {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardHeader
                title="Vereinsmitglieder"
                subheader="Alle Mitglieder die dem Verein angehören"
            />
            <List className={classes.content}>
                {mockUser.map((user) => (
                    <ListItem button>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${user.firstName} ${user.lastName}`}
                            secondary="1. Vorsitzender"
                        />
                        <ListItemSecondaryAction>
                            <Tooltip title="E-Mail schreiben">
                                <IconButton
                                    edge="end"
                                    component="a"
                                    href={`mailto:${user.email}`}
                                >
                                    <EmailIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                >
                    Mitglied einladen
                </Button>
            </CardActions>
        </Card>
    )
}
