import ActionBar from "../../shared/actionBar/ActionBar"
import BreederList from "./components/BreederList"
import { Grid, makeStyles } from "@material-ui/core"
import Statistic from "./components/Statistic"
import Appointments from "./components/Appointments"
import LitterList from "./components/LitterList"
import NotificationList from "./components/NotificationList"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grid: {},
    gridItem: {},
}))

export default function DashboardContainer() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <ActionBar />
            <Grid
                container
                spacing={2}
                className={classes.grid}
                alignItems="stretch"
            >
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LitterList />
                        </Grid>
                        <Grid item xs={12}>
                            <Appointments />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <NotificationList />
                </Grid>
                <Grid item xs={12} md={4} className={classes.gridItem}>
                    <BreederList />
                </Grid>
                <Grid item xs={12}>
                    <Statistic />
                </Grid>
            </Grid>
        </div>
    )
}
