import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "white",
            main: "#21b2a6",
            dark: "#21b2a6",
        },
        secondary: {
            light: "#123a53",
            main: "#123a53",
            contrastText: "#000000",
        },
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                color: "white",
            },
        },
        MuiCardActions: {
            root: {
                padding: 16,
                justifyContent: "flex-end",
            },
        },
        MuiCardContent: {
            root: {
                padding: "0 16px 24px 16px",
            },
        },
        MuiDivider: {
            light: {
                backgroundColor: "rgba(255, 255, 255, 0.7)",
            },
        },
    },
})

export default theme
