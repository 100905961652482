import { client } from "./client"
import { CREATE_CLUB } from "./queries"
import { ClubRegistrationData } from "../types"
import { FetchResult } from "@apollo/client"

export function createClub(data: ClubRegistrationData): Promise<boolean> {
    return client
        .mutate({ mutation: CREATE_CLUB, variables: data })
        .then((res: FetchResult<{ createClub: ClubRegistrationData }>) => {
            return !!res?.data?.createClub?.id
        })
        .catch(() => false)
}
