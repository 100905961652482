import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"

const useStyles = makeStyles(
    (theme) => ({
        root: {
            color: theme.palette.primary.light,
            height: "100%",
            backgroundImage:
                "url('https://www.panocreation.de/images/breedingmanager/rabbit.jpg')",
            backgroundSize: "cover",
            "& span, p": {
                color: theme.palette.primary.light,
            },
            "& p": {},
        },
        advantagesWrapper: {
            height: "100%",
            backgroundColor: "rgba(18, 58, 83, 0.7)",
        },
    }),
    { name: "Advantages" }
)

const advantages = [
    {
        id: "1",
        title: "Zusammenarbeit innerhalb des Vereins",
        subtitle:
            "Die Daten der Tiere können vom Züchter einfach an den Verein übermittelt werden.",
    },
    {
        id: "2",
        title: "Übersicht aller Tiere des Vereins",
        subtitle:
            "Ansicht sämtlicher Tiere des Vereins, der Nachkommen und Bewertungen.",
    },
    {
        id: "3",
        title: "Umfangreiche Druckfunktionen",
        subtitle:
            "Vereinszuchtbuch, Zuchtmeldungen, Impfzeugnissen, Tierliste, Tiere und Bewertungen",
    },
    {
        id: "4",
        title: "Statistische Auswertungen",
        subtitle: "Verschiedene Auswertungen über Rassen und Jahrgänge",
    },
]

export default function Advantages() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <List className={classes.advantagesWrapper}>
                {advantages.map((adv) => (
                    <ListItem key={adv.id}>
                        <ListItemAvatar>
                            <CheckIcon />
                        </ListItemAvatar>
                        <ListItemText primary={adv.title} secondary={adv.subtitle} />
                        <ListItemSecondaryAction />
                    </ListItem>
                ))}
            </List>
        </div>
    )
}
