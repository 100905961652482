import { MuiThemeProvider } from "@material-ui/core"
import React from "react"
import ReactDOM from "react-dom"
import AppRouter from "./router"
import theme from "./config/theme"
import { ApolloProvider } from "@apollo/client"
import { client } from "./config/client"

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <MuiThemeProvider theme={theme}>
                <AppRouter />
            </MuiThemeProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById("root")
)
