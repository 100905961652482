import { withUserContext } from "../../../context/userConsumer"
import {
    AppBar,
    Drawer,
    Hidden,
    IconButton,
    makeStyles,
    Toolbar,
    Typography,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { useState } from "react"
import { UserContext } from "../../../types"
import Menu from "../nenu/Menu"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
        fontWeight: 600,
        fontSize: "2.1rem",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.light,
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.secondary.dark,
    },
}))

function Header({ context }: { context?: UserContext }) {
    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Vereinszucht
                    </Typography>
                </Toolbar>
            </AppBar>
            <nav>
                <Hidden smUp implementation="css">
                    <Drawer
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={handleDrawerToggle}
                            onKeyDown={handleDrawerToggle}
                        >
                            <Menu />
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <Menu />
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}

export default withUserContext(Header)
