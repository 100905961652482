import {
    Card,
    List,
    ListItem,
    CardHeader,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    CardActions,
    Button,
} from "@material-ui/core"
import EventNoteIcon from "@material-ui/icons/EventNote"
import moment from "moment"
import "moment/locale/de"
import { Add } from "@material-ui/icons"

const appointments = [
    {
        id: "1",
        title: "Bundesschau",
        date: "2021-01-01",
    },
    {
        id: "2",
        title: "Jungtierschau in Köln",
        date: "2021-03-05",
    },
]

export default function Appointments() {
    return (
        <Card>
            <CardHeader title="Termine" subheader="Wichtige Termine und Schauen" />
            <List>
                {appointments.map((appointment) => (
                    <ListItem button>
                        <ListItemAvatar>
                            <EventNoteIcon />
                        </ListItemAvatar>
                        <ListItemText
                            primary={appointment.title}
                            secondary={moment(appointment.date)
                                .locale("de")
                                .format("dddd, DD.MM.YYYY")}
                        />
                        <ListItemSecondaryAction />
                    </ListItem>
                ))}
            </List>
            <CardActions>
                <Button variant="contained" color="primary" startIcon={<Add />}>
                    Neuer Termin
                </Button>
            </CardActions>
        </Card>
    )
}
