import ActionBar from "../../shared/actionBar/ActionBar"
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Button,
    Grid,
    TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Advantages from "./components/Advantages"
import Registration from "./components/Registration"

const useStyles = makeStyles((theme) => ({
    loginWrapper: {
        height: "100%",
    },
}))

export default function LoginContainer() {
    const classes = useStyles()

    return (
        <div>
            <ActionBar />
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card className={classes.loginWrapper}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <CardHeader
                                    title="Anmeldung"
                                    subheader="Melde dich mit deiner E-Mail und deinem Kennwort an"
                                />
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                label="Vereinskennung"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                type="email"
                                                label="E-Mail Adresse"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                type="password"
                                                label="Kennwort"
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button color="primary" variant="contained">
                                        Anmelden
                                    </Button>
                                </CardActions>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Advantages />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <Registration />
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
