import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { UserContext } from "../../../types"
import { withUserContext } from "../../../context/userConsumer"
import HomeIcon from "@material-ui/icons/Home"
import HelpIcon from "@material-ui/icons/Help"
import GavelIcon from "@material-ui/icons/Gavel"
import StorageIcon from "@material-ui/icons/Storage"

const useStyles = makeStyles(
    (theme) => ({
        root: { textAlign: "center", color: theme.palette.primary.light },
        list: {
            color: theme.palette.primary.light,
            "& svg": {
                color: theme.palette.primary.light,
            },
        },
        fullList: {
            width: "50%",
        },
        logo: {
            width: 120,
            marginTop: theme.spacing(2),
        },
    }),
    { name: "Menu" }
)

function Menu({ context }: { context: UserContext }) {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img alt="logo" className={classes.logo} src="https://panobreeder.de/logo.png" />
            <br />
            <Typography className={classes.root}>PanoBreeder</Typography>
            <br />
            <br />
            <List className={classes.list}>
                <ListItem color="inherit" button component={Link} to="/">
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button component={Link} to="/help">
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hilfe" />
                </ListItem>
            </List>
            <Divider light />
            <List className={classes.list}>
                <ListItem button component={Link} to="/privacy">
                    <ListItemIcon>
                        <StorageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Datenschutz" />
                </ListItem>

                <ListItem button component={Link} to="/imprint">
                    <ListItemIcon>
                        <GavelIcon />
                    </ListItemIcon>
                    <ListItemText primary="Impressum" />
                </ListItem>
            </List>
        </div>
    )
}

Menu.propTypes = {}

Menu.defaultProps = {}

export default withUserContext(Menu)
